@import "../../../mixins/global.scss";

.Review {
  &__productImageContainer {
    @include rxc-desktop-only {
      display: none;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__image {
      display: block;
      align-items: center;
      justify-content: center;
      width: auto;
      max-width: 100%;
      max-height: 220px;
      margin-bottom: 1rem;
    }
  }

  &__includedContainer {
    width: fit-content;
    height: fit-content;
    margin-bottom: 8px;

    &__checkBox {
      display: none !important;
    }

    &__accordion {
      &__open {
        display: block;

        & ul {
          padding-left: 20px;
          margin: 12px 0;

          & li {
            font-family: $rxc-sun-default-sofia-font;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #1f1f24;
            padding-bottom: 0.3rem;

            &::marker {
              font-size: 0.6rem;
            }
          }
        }
      }

      &__closed {
        display: none;
      }

      &Button {
        font-family: $rxc-sun-default-sofia-font;
        font-size: 14px;
        letter-spacing: 0.16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: $rxc-blue-skin-default;
        text-decoration: underline;
        cursor: pointer;
        user-select: none;

        @include rxc-mobile-only {
          font-size: 12px;
          font-family: $rxc-sun-default-sofiaBold-font;
          line-height: 1.33;
        }
      }

      &Button.closed::after {
        position: relative;
        top: 3px;
        margin-left: 4px;
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.86195 5.52876C2.6016 5.78911 2.6016 6.21122 2.86195 6.47157L7.52862 11.1382C7.78896 11.3986 8.21108 11.3986 8.47142 11.1382L13.1381 6.47157C13.3984 6.21122 13.3984 5.78911 13.1381 5.52876C12.8777 5.26841 12.4556 5.26841 12.1953 5.52876L8.00002 9.72402L3.80476 5.52876C3.54441 5.26841 3.1223 5.26841 2.86195 5.52876Z' fill='%23557B92'/%3E%3C/svg%3E%0A");
      }

      &Button.open::after {
        position: relative;
        top: 3px;
        margin-left: 4px;
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.138 10.4712C13.3983 10.2109 13.3983 9.78878 13.138 9.52843L8.47132 4.86177C8.21097 4.60142 7.78886 4.60142 7.52851 4.86177L2.86185 9.52843C2.6015 9.78878 2.6015 10.2109 2.86185 10.4712C3.1222 10.7316 3.54431 10.7316 3.80466 10.4712L7.99992 6.27598L12.1952 10.4712C12.4555 10.7316 12.8776 10.7316 13.138 10.4712Z' fill='%23557B92'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &__topBadge {
    &__desktop {
      &>div {
        background-image: none !important;
        height: 31px !important;
        font-family: $rxc-sun-default-sofia-font !important;
        font-style: normal;
        font-weight: 600;
        line-height: 1.14;
        font-size: 14px;
        display: flex;
        text-transform: lowercase !important;
        align-items: center;
        text-align: center;
        letter-spacing: 0.5px;
        color: #222 !important;
        background-color: #f0eae2;
      }

      &>p {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        width: 100%;
        height: 31px;
        font-family: $rxc-sun-default-sofiaBold-font;
        font-size: 16px;
        color: #182446 !important;
        background-color: #f0eae2;
      }

      @include rxc-mobile-only {
        display: none;
      }
    }

    &__mobile {
      &>div {
        background-image: none !important;
        height: 31px !important;
        font-family: $rxc-sun-default-sofiaBold-font !important;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: lowercase !important;
        letter-spacing: 0.16px;
        color: #222 !important;
        background-color: #f0eae2;
      }

      &>p {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        width: 100%;
        height: 31px;
        font-family: $rxc-sun-default-sofiaBold-font;
        font-size: 12px;
        color: #182446 !important;
        background-color: #f0eae2;
      }

      @include rxc-desktop-only {
        display: none;
      }
    }
  }

  &__frameContainer {
    display: flex;
    width: 100%;
    padding-top: 16px;

    &__contentContainer {
      display: flex;
      flex-direction: column;
      width: 80%;

      @include rxc-mobile-only {
        width: 100%;
      }

      &__title {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        color: #222222;

        @include rxc-smaller-mobile-only {
          font-size: 12px;
        }

        @include rxc-mobile-only {
          display: flex;
          justify-content: space-between;
        }
      }

      &__name {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        color: #222222;

        @include rxc-desktop-only {
          padding: 0 0.5rem 4px 0;
          width: 60%;
        }

        @include rxc-mobile-only {
          padding: 0 0.5rem 4px 0;
        }

        @include rxc-smaller-mobile-only {
          font-size: 12px;
        }
      }
    }

    &__price {
      text-align: right;
      color: #222222;

      @include rxc-desktop-only {
        width: 25%;
      }

      @include rxc-mobile-only {
        width: 20%;
      }

      .StepPrice__container__inner__strikePrice {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        @include rxc-smaller-mobile-only {
          font-size: 10px;
        }
      }

      .StepPrice__container__inner__price {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;

        @include rxc-smaller-mobile-only {
          font-size: 14px;
        }
      }
    }
  }

  &__lensContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .Review__frameContainer__contentContainer__title {
      @include rxc-desktop-only {
        margin-bottom: 24px;
      }

      @include rxc-mobile-only {
        margin-bottom: 0px;
      }
    }

    .Review__frameContainer__contentContainer {
      width: unset;

      @include rxc-mobile-only {
        flex-direction: row !important;
        align-items: baseline;
      }
    }
  }

  &__toggle {
    display: flex;
    margin: 0px 0px 24px 0px !important;

    @include rxc-mobile-only {
      margin: 16px 0px 0px !important;
    }

    &__label {
      font-family: "Mulish";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      cursor: pointer;
    }

    &__icon {
      cursor: pointer;
      height: 16px;
      width: 16px;
      margin-left: 8px;
      background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.99998 12.943L0.195312 5.13798L1.13798 4.19531L7.99998 11.057L14.862 4.19531L15.8046 5.13798L7.99998 12.943Z' fill='black'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  &__stepsSeparator {
    min-height: 1px;
    opacity: 0.3;
    background-color: #666666;
    margin-top: 16px;
  }

  &__priceSummary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include rxc-desktop-only {
      position: absolute;
      bottom: 72px;
      left: 0;
      right: 0;
      min-height: 81px;
      height: fit-content;
      box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.05);
      background-color: #f1f2f2;
    }

    @include rxc-mobile-only {
      margin-bottom: 13px;
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0;

      &__visionCareContainer {
        display: flex;
        align-items: center;
        width: 100%;

        &__visionCareLabel {
          font-family: "Mulish";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #43358d;
          display: flex;
          align-items: center;
        }

        &__visionCareIcon {
          position: relative;
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-left: 4px;
          background-image: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8 0C3.58867 0 0 3.58867 0 8C0 12.4113 3.58867 16 8 16C12.4113 16 16 12.4113 16 8C16 3.58867 12.4113 0 8 0ZM8 14.6667C4.324 14.6667 1.33333 11.676 1.33333 8C1.33333 4.324 4.324 1.33333 8 1.33333C11.676 1.33333 14.6667 4.324 14.6667 8C14.6667 11.676 11.676 14.6667 8 14.6667Z' fill='%2343358D'/%3e%3cpath d='M8.66634 6.66699H7.33301V12.0003H8.66634V6.66699Z' fill='%2343358D'/%3e%3cpath d='M8.66634 4H7.33301V5.33333H8.66634V4Z' fill='%2343358D'/%3e%3c/svg%3e");

          &:hover {
            & .VisionCareTooltip {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }

          & .VisionCareTooltip {
            display: none;
            position: absolute;
            padding: 12px 16px;
            font-family: "Mulish";
            font-style: normal;
            top: -115px;
            left: -140px;
            width: 320px;
            height: 98px;
            background: #3d3c3c;
            border-radius: 2px;
            color: white;

            &::after {
              content: "";
              position: absolute;
              bottom: -8px;
              left: 44%;
              height: 16px;
              width: 16px;
              background-image: url("data:image/svg+xml,%3csvg width='16' height='8' viewBox='0 0 16 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 8L0 0L16 0L8 8Z' fill='%233D3C3C'/%3e%3c/svg%3e");
            }

            &__title {
              font-weight: 700;
              font-size: 16px;
              line-height: 22px;
              margin: 0px 0px 8px 0px;
            }

            &__description {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              margin: 0px;
            }
          }
        }

        @include rxc-desktop-only {
          display: none;
        }
      }

      @include rxc-desktop-only {
        padding: 0 40px;
      }

      &__label {
        font-family: $rxc-rayban-lato-font;
        font-size: 14px;
        color: #1f1f24;
      }

      &__labelTotal {
        display: block;

        @include rxc-desktop-only {
          margin-top: 1px;
        }

        @include rxc-mobile-only {
          display: none;
        }

        text-transform: uppercase;
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: left;
        color: #222222;
      }

      &__labelTotalMobile {
        display: none;

        @include rxc-mobile-only {
          display: block;
          font-family: "sofia-pro-bold";
          font-size: 16px;
          color: #1f1f24;
          font-size: 20px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.6;
          letter-spacing: 0.5px;
          text-align: left;
          color: #222222;
          text-transform: uppercase;
        }
      }
    }

    &__paymentInstallment {
      cursor: pointer;
      display: flex;
      align-self: flex-start;
      font-family: "sofia-pro-bold";
      text-decoration: underline;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #222222;

      @include rxc-mobile-only {
        &.marginTop {
          margin-top: 10px;
        }
      }

      @include rxc-desktop-only {
        padding: 0 40px;
      }
    }

    &__multipleInstallments {
      cursor: unset;
      display: flex;
      align-self: flex-start;
      font-family: "sofia-pro-bold";
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #222222;
      pointer-events: none;

      &::after {
        content: "Learn More";
        margin-left: 7px;
        text-decoration: underline;
        cursor: pointer;
        font-weight: 600;
        pointer-events: all;
      }

      @include rxc-mobile-only {
        display: block;

        &.marginTop {
          margin-top: 10px;
        }

        &::after {
          content: "Learn More";
          margin-left: 3px;
          cursor: pointer;
          pointer-events: all;
          font-weight: 600;
          text-decoration: underline;
        }
      }

      @include rxc-desktop-only {
        padding: 0 40px;
      }
    }

    &__separator {
      width: 100%;
      height: 1px;
      opacity: 0.3;
      background-color: #666666;
      margin-bottom: 19px;
    }
  }

  &__warrantyContainer {
    display: flex;
  }

  &__stickyWrapper {
    @include rxc-mobile-only {
      position: fixed;
      bottom: 0;
      background-color: white;
    }
  }

  &__buttonsMobileContainer {
    @include rxc-desktop-only {
      display: none;
    }

    @include rxc-mobile-only {
      margin: 24px 0 46px 0;
    }
  }
}