.LensAdvisorBanner {
  &__container {
    width: 100%;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin: 16px 0px;
    background: radial-gradient(
        238.29% 109.48% at 0% 50%,
        #f7e7ff 0%,
        rgba(195, 214, 255, 0.67) 65.58%,
        #e0f0ff 91.96%
      ),
      #fff;

    &__label {
      margin: 0;
      padding: 0;
    }
  }
}
